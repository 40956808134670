import myRequest from '../http/index.js'
// 接口：搜索游记
export function searchuTakeAPI(params){
    return myRequest({
        url:"index.php/index/index/searchuTake",         
        params,
        method:"post"
      })
}
// 接口：搜索活动
export function searchGoodsAPI(params){
  return myRequest({
      url:"index.php/index/index/searchGoods",         
      params,
      method:"post"
    })
}